// JS libraries included in plugins.min.js
jQuery(window).setBreakPoints([360,540,768,1024,1200,1320,1560]);

// Detects Touch Event
window.addEventListener('touchstart',function onFirstTouch(){
	document.documentElement.className += " touch";
	window.removeEventListener('touchstart', onFirstTouch, false);
}, false);

// Global Variables - WordPress Theme information
var WPTHEME = '/wp-content/themes/shescales/';
var DOMAIN = location.protocol + "//" + location.host;

// Global Variables - Page/Viewport Heights
var pageHeight;
var windowHeight;
var windowWidth;
var headerHeight = 70;
var homeBannerHeight = 0;

// Global Variables
var windowPathname;
var header;
var ajaxLoader;
var resizeTimeout;
var hPHeroController;
var hPHeroScene;
var socialShare;
var irStickyNav;
var irStickyNavHeight = 76;

// ScrollMagic Controller
var controller = new ScrollMagic.Controller();

// Global Variables - Vimeo Player for Modal
var vimeoModalPlayer;

// Global - Google Tag Manager
window.dataLayer = window.dataLayer || [];

// jQuery Document Ready
var $ = jQuery;
jQuery(document).ready(function($){
	// Sets Element Height Values
	pageHeight = $('body').outerHeight();
	windowHeight = vhCheck().vh;
	windowWidth = window.innerWidth;
	windowPathname = window.location.pathname;
	header = $('.ss-header');
	ajaxLoader = $(".ajax-loader");

	//Global Elements
	initFadeScenes();
	initHeaderMenu();
	initFooter();

	//Page - Home
	if (isPage('home')){
		initHomeHeroParallax();
		initHomeSideMenu();
		initHomeVideo();
		initHomeTeam();
		initHomeBoard();
		initHomeRollingNumbers();
		initHomeInstagram();
		initNewsletterSignup();
		scrollToSection();
	}
	//Page - Apply
	else if (isPage('apply')){
		initApply();
		initNewsletterSignup();
	}
	//Page - Insights & Resources
	else if (isPage('insight')){
		initInsightsResources();
		initNewsletterSignup();
	}

	// Windows Resize Listener
	$(window).resize(function(){
		var resizedWindowWidth = window.innerWidth;

		if (windowWidth > 767) {
			$('.ss-header__container-menu-icon').removeClass('ss-header__container-menu-icon-active');
			$('.ss-header__container-menu-items').removeClass('ss-header__container-menu-items-active');
		}

		//Insight and resources hide menus on any resize event
		$("#js-insights-desktop-menu, #js-insights-mobile-menu").fadeOut();

		//For contact us form since we are using position absolute on desktop we need to make sure the padding ("ss-footer-active") is added only when needed.
		if (windowWidth > 991 && $("#js-footer-contact-us").is(":visible")) {
			$(".ss-footer").addClass("ss-footer-active");
		} else {
			$(".ss-footer").removeClass("ss-footer-active");
		}

		if (windowWidth !== resizedWindowWidth) {
			windowWidth = resizedWindowWidth;
		}

		clearTimeout(resizeTimeout);
		resizeTimeout = setTimeout(function(){
			if (isPage('home')){
				//initHomeHeroParallax();
				//showAboutVideoandContent("#js-inspired-video", "#js-inspired-content, .ss-home-inspired__col-left-copy, .ss-home-inspired__col-left-btn");
			} else if (isPage('apply')){

			} else if (isPage('insight')){
				articlesGrid.isotope('layout');
				articleSocialShare();
				enableIRstickyNav();
			}
		}, 250);

		//resize event to show all items since desktop and mobile hide different elements
		function showAboutVideoandContent(fOut, fIn){
			var aboutVideo = document.getElementById("about-video");
			var aboutPlayer = new Vimeo.Player(aboutVideo);
			aboutPlayer.pause();
			$(fOut).fadeOut(function() {
				$(fIn).fadeIn();
			});
		}

	});

});

// ===================================================================
// Section: Home
// ===================================================================
function initHomeHeroParallax(){
	var initialHero = $(".ss-home-hero-title-initial, #ss-home-hero-arrow");
	var secondaryHero = $(".ss-home-hero-title-secondary-content");
	var eventProgress = "";
	var eventProgressUpdated = "";

	if (windowWidth <= 991 && hPHeroScene) {
		// Mobile - No Animation
		hPHeroScene.destroy(true);
		hPHeroScene = null;
	} else if (windowWidth >= 992) {
		// Desktop Only
		if (hPHeroScene) {
			hPHeroScene.destroy(true);
			hPHeroScene = null;
		}

		hPHeroController = new ScrollMagic.Controller({
			globalSceneOptions: {
				triggerHook: 'onLeave',
				duration: $(".ss-home-hero").outerHeight() / 1.5,
				offset: 1
			}
		});

		// build scene
		hPHeroScene = new ScrollMagic.Scene({
			triggerElement: ".ss-home-hero"
		})
		.setPin(".ss-home-hero")
		// .addIndicators() // add indicators (requires plugin)
		.addTo(hPHeroController)
		.on("progress", function (event) {
				eventProgressUpdated = event.progress * 15

				if (event.scrollDirection === "FORWARD") {
					if (eventProgress === "" || eventProgress * 15 + .1 <= eventProgressUpdated) {
						eventProgress = event.progress;
					}
				} else {
					if (eventProgress === "" || eventProgress * 15 + .1 >= eventProgressUpdated) {
						eventProgress = event.progress;
					}
				}

				//For the title transition
				if (event.progress < 0.5) {
					initialHero.css("opacity", 1 - eventProgress * 2).css("transform", "translateY(" + - eventProgress * 20 + "rem)").css("transition", "unset");
					secondaryHero.css("opacity", 0).css("transform", "translateX(6.5rem)").css("transition", "unset");

					if (windowWidth < 992) {
						$(".ss-home-hero-bg").css("opacity", 1 - eventProgress * 1.25);
					}
				} else {
					initialHero.css("opacity", 0).css("transform", "translateY(-10rem)");
					secondaryHero.css("opacity", (eventProgress - .5) * 2).css("transform", "translateY(" + (6.5 - (eventProgress - .5) * 12.5) + "rem)");
				}
		})
		.on("start", function (event) {
				initialHero.css("opacity", 1).css("transform", "translateX(0rem)");
		})
		.on("leave", function (event) {
				if (event.scrollDirection === "FORWARD") {
					secondaryHero.css("opacity", 1).css("transform", "translateX(0rem)");
				}
		});
	}
}
function initHomeSideMenu(){
	var heroHeight = 0;
	var footerHeight = 0;
	var allNavLinks = $("ss.home-nav a");
	var navAboutLink = $("#js-nav-about");
	var navServicesLink = $("#js-nav-services");
	var navBrandsLink = $("#js-nav-brands");
	var navTeamLink = $("#js-nav-team");
	var navBoardLink = $("#js-nav-board");
	var navCommunityLink = $("#js-nav-community");

	// Checks if homepage banner is shown
	if ($('body').hasClass('showHomeBanner')){
		homeBannerHeight = 70;
	}

	// Updates page/hero/footer height AFTER hero pin generated
	pageHeight = $('body').outerHeight();
	footerHeight = $('footer').outerHeight();
	if ($('.ss-home-hero').closest('.scrollmagic-pin-spacer').length){
		heroHeight = $('.ss-home-hero').closest('.scrollmagic-pin-spacer').outerHeight();
	} else {
		heroHeight = $('.ss-home-hero').outerHeight();
	}

	// Home Page mini-nav
	if (windowWidth >= 992) {
		var topNav = new ScrollMagic.Scene({
			triggerElement: ".ss-home-services",
			triggerHook: 'onEnter',
		}).addTo(controller);
		topNav.on("enter", function(event){
			header.addClass("ss-header-active");
			$('body').addClass('mini-header-active');
		}).on("leave", function(event){
			header.removeClass("ss-header-active");
			$('body').removeClass('mini-header-active');
		});
		//topNav.addIndicators();
	}

	// Desktop Only
	if (windowWidth >= 1200) {
		//Side Nav
		var sideNav = new ScrollMagic.Scene({
			triggerElement: ".ss-home-services",
			triggerHook: 'onEnter',
			duration: pageHeight - heroHeight - homeBannerHeight + (footerHeight / 2)
		}).addTo(controller);
		sideNav.on("enter", function(event){
			$(".ss-home-nav").fadeIn();
		}).on("leave", function(event){
			$(".ss-home-nav").fadeOut();
		});
		//sideNav.addIndicators();

		// Side Menu: About (Hero)
		var navAbout = new ScrollMagic.Scene({
			triggerElement: ".ss-home-hero",
			triggerHook: "onLeave",
			duration: heroHeight - headerHeight - homeBannerHeight - 5
		}).addTo(controller);
		navAbout.on("enter", function(event){
			navAboutLink.addClass("nav-active");
		}).on("leave", function(event){
			navAboutLink.removeClass("nav-active");
		});
		//navAbout.addIndicators();

		// Side Menu: Our Services
		var navServices = new ScrollMagic.Scene({
			triggerElement: ".ss-home-services",
			triggerHook: "onLeave",
			offset: 1 - headerHeight - homeBannerHeight - 5,
			duration: $(".ss-home-services").outerHeight()
		}).addTo(controller);
		navServices.on("enter", function(event){
			navServicesLink.addClass("nav-active");
		}).on("leave", function(event){
			navServicesLink.removeClass("nav-active");
		});
		//navServices.addIndicators();

		// Side Menu: Our Clients (Brands)
		var navScale = new ScrollMagic.Scene({
			triggerElement: ".ss-home-cohort",
			triggerHook: "onLeave",
			offset: 1 - headerHeight - homeBannerHeight - 5,
			duration: $(".ss-home-cohort").outerHeight() + $(".ss-home-brands").outerHeight()
		}).addTo(controller);
		navScale.on("enter", function (event){
			navBrandsLink.addClass("nav-active");
		}).on("leave", function (event){
			navBrandsLink.removeClass("nav-active");
		});
		//navScale.addIndicators();

		// Side Menu: Our Team
		var navTeam = new ScrollMagic.Scene({
			triggerElement: ".ss-home-team",
			triggerHook: "onLeave",
			offset: 1 - headerHeight - homeBannerHeight - 5,
			duration: $(".ss-home-team").outerHeight()
		}).addTo(controller);
		navTeam.on("enter", function (event){
			navTeamLink.addClass("nav-active");
		}).on("leave", function (event){
			navTeamLink.removeClass("nav-active");
		});
		//navTeam.addIndicators();

		// Side Menu: Our Team
		var navBoard = new ScrollMagic.Scene({
			triggerElement: ".ss-home-board",
			triggerHook: "onLeave",
			offset: 1 - headerHeight - homeBannerHeight - 5,
			duration: $(".ss-home-board").outerHeight()
		}).addTo(controller);
		navBoard.on("enter", function (event){
			navBoardLink.addClass("nav-active");
		}).on("leave", function (event){
			navBoardLink.removeClass("nav-active");
		});
		//navBoard.addIndicators();

		// Side Menu: Community (Social)
		var navCommunity = new ScrollMagic.Scene({
			triggerElement: ".ss-home-social",
			triggerHook: "onLeave",
			offset: 1 - headerHeight - homeBannerHeight - 5,
			duration: $(".ss-home-social").outerHeight()
		}).addTo(controller);
		navCommunity.on("enter", function (event){
			navCommunityLink.addClass("nav-active");
		}).on("leave", function (event) {
			navCommunityLink.removeClass("nav-active");
		});
		//navCommunity.addIndicators();
	}

	// Click ScrollTo locations
	scrollTo("#js-nav-services", ".ss-home-services");
	scrollTo("#js-nav-brands", ".ss-home-cohort");
	scrollTo("#js-nav-team", ".ss-home-team");
	scrollTo("#js-nav-board", ".ss-home-board");
	scrollTo("#js-nav-community", ".ss-home-social");
	$("#js-nav-about, #ss-home-hero-arrow").click(function (e) {
		e.preventDefault();
		$('html,body').animate({
			scrollTop: $(".ss-home-hero").outerHeight() / 1.5 + 2
		}, 1000);
	});
}
function initHomeVideo(){
	$("#about-video-btn").click(function (e){
		e.preventDefault();

		if (windowWidth > 991) {
				toggleAboutVideoandContent("#js-inspired-content", "#js-about-video", "play", true);
		} else {
			toggleAboutVideoandContent(".ss-home-inspired__col-left-copy, .ss-home-inspired__col-left-btn, .ss-home-inspired__col-right", "#js-about-video", "play", false);
		}
	});
	$("#js-about-close").click(function (e){
		e.preventDefault();

		if (windowWidth > 991) {
				toggleAboutVideoandContent("#js-about-video", "#js-inspired-content", "pause", true);
		} else {
				toggleAboutVideoandContent("#js-about-video", ".ss-home-inspired__col-left-copy, .ss-home-inspired__col-left-btn, .ss-home-inspired__col-right", "pause", false);
		}
	});
	function toggleAboutVideoandContent(fOut, fIn, control, scrollto){
		var aboutVideo = document.getElementById("about-video");
		var aboutPlayer = new Vimeo.Player(aboutVideo);

		if (scrollto) {
			$('html,body').animate({
					scrollTop: $(".ss-home-inspired").position().top - headerHeight
			}, 1000);
		}

		$(fOut).fadeOut(function() {
			$(fIn).fadeIn(function() {
					if (control === "play") {
						aboutPlayer.play();
					} else if (control === "pause") {
						aboutPlayer.pause();
					}
			});
		});
	}
}
function initHomeTeam(){
	var imageClasses = ["image-team-sue","image-team-michelle","image-team-jane"];

	$("#team-sue, #team-michelle, #team-jane").on("mouseover click", function(e) {
		e.preventDefault();
		$(".ss-home-team__col-title-default, .ss-home-team__col-title-sue, .ss-home-team__col-title-michelle, .ss-home-team__col-title-bianca, .ss-home-team__col-title-jane").css("opacity", 0);

		switch ($(e.target).attr("id")) {
				case "team-sue":
					filterTeamMembers("ss-home-team__col-title-sue", "image-team-sue");
					break;
				case "team-michelle":
					filterTeamMembers("ss-home-team__col-title-michelle", "image-team-michelle");
					break;
				case "team-jane":
					filterTeamMembers("ss-home-team__col-title-jane", "image-team-jane");
					break;
		}
	});
	$("#team-sue, #team-michelle, #team-jane").on("mouseout", function() {
		$(".ss-home-team__col-title-sue, .ss-home-team__col-title-michelle, .ss-home-team__col-title-jane").css("opacity", 0);
		$(".ss-home-team__col-title-default").css("opacity", 1);

		$.each(imageClasses,function(i,v) {
				$("." + v).removeClass("inactive");
		});
	});

	function filterTeamMembers(titleClass, imageClass) {
		$("." + titleClass).css("opacity", 1);
		$.each(imageClasses,function(i,v) {
				if (v !== imageClass) {
					$("." + v).addClass("inactive");
				} else {
					$("." + v).removeClass("inactive");
				}
		});
	}
}
function initHomeBoard(){

	var carousel = $(".ss-home-board .board-carousel .slides");
	var carouselControl = $('.ss-home-board .board-carousel-controls #carouselControl');

	carouselControl.change(function(){
		var controlValue = this.value;
		carousel.slick('slickGoTo', controlValue - 1);
	});

	carousel.on('init', function(event, slick){
		var carouselCount = slick.slideCount || 1;
		carouselControl.attr('max',carouselCount);
		carouselControl.addClass('showSlides_'+carouselCount)
	});

	carousel.slick({
		speed: 300,
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: false
	}).on('afterChange', function(event, slick, slide){
		carouselControl.val(slide+1);
	});
}
function initHomeRollingNumbers(){
	$('.ss-home-inspired .ss-home-inspired__col-right').on('inView', function(){
		$('.count').each(function(){
			$(this).prop('Counter',0).animate({
					Counter: $(this).text()
			}, {
					duration: 2000,
					easing: 'swing',
					step: function (now) {
						$(this).text(Math.ceil(now));
					}
			});
		});
		$(this).unbind('inView');
	});
}
function initHomeInstagram(){
	$(".ss-home-social__col-right-slider").slick({
		infinite: false,
		swipe: false,
		variableWidth: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1500,
				settings: {slidesToShow: 2}
			},
			{
				breakpoint: 992,
				settings: {slidesToShow: 2, swipe: true, swipeToSlide: true}
			},
			{
				breakpoint: 576,
				settings: {slidesToShow: 1, centerMode: true, infinite: true, swipe: true, swipeToSlide: true}
			}
		]
	});
}

function scrollToSection() {
	//Nav Clients click event (linking from same page section is on)
	$("#navClients").click(function(e) {
		if (isPage('home')) {
			e.preventDefault();
			$('#js-header-menu').removeClass('ss-header__container-menu-icon-active');
			$('.ss-header__container-menu-items').removeClass('ss-header__container-menu-items-active');
			$("html, body").removeClass("no-scroll");
			$('html,body').animate({
				scrollTop: $(".ss-home-cohort").position().top - headerHeight
			}, 1000);
		}
	});

	//If url contains #clients (linking from another page)
	if (window.location.href.indexOf("#clients") > -1) {
		$('html,body').animate({
			scrollTop: $(".ss-home-cohort").position().top - headerHeight
		}, 1000);
	}
}



// ===================================================================
// Section: Apply
// ===================================================================
function initApply(){
	// Right Column Fade-in
	window.setTimeout(function(){
		$('.ss-apply__form-col-right .ss-apply-animate').addClass('ss-apply-animate-in-view');
	}, 1000);

	$("#ss-apply-form").on('submit', function(event){
		event.preventDefault();
		if ($(this).valid()){
			ajaxLoader.css("display", "flex");

			// Step 1 - Google reCAPTCHA
			grecaptcha.ready(function(){
				grecaptcha.execute('6Lf03z8eAAAAAIFmKhv7D9cCtt_uIx_FlNO-Ij37', {action: 'jotformSubmit'}).then(function(token){
					var postData = new Array;
					postData.push({name:'token', value:token});
					postData.push({name:'action', value:'jotformSubmit'});

					// AJAX reCAPTCHA validation
					$.ajax({
						type: "POST",
						url: WPTHEME+"jotform-apply.php",
						data: postData,
						cache: false
					}).done(function(data){
						var jsonData = JSON.parse(data);
						console.log("ajax returned: "+data);
						if (jsonData.success == "1"){
							submitApply();
						} else {
							console.log('reCAPTCHA score too low');
						}
					}).fail(function(xhr, textStatus, errorThrown){
						console.log("ajax failure: "+textStatus+"|"+errorThrown);
					});

				});
			});

			// Step 2 - Submit Form
			function submitApply(){
				const form = document.querySelector('form#ss-apply-form');
				const formData = new FormData(form);

				// Adds JotForm security
				formData.append('formID', '241936833574163');
				formData.append('simple_spc', '241936833574163-241936833574163');

				// Removes File upload if empty
				var formFile = formData.get('q12_uploadYour[]');
				if (formFile.name.length == 0){
					formData.delete('q12_uploadYour[]');
				}

				// Removes Country unless Address is completely filled
				var formAddress = formData.get('q8_shescales-apply-company-address[addr_line1]');
				var formCity = formData.get('q8_shescales-apply-company-address[city]');
				var formState = formData.get('q8_shescales-apply-company-address[state]');
				var formZip = formData.get('q8_shescales-apply-company-address[postal]');
				if (formAddress.length == 0 || formCity.length == 0 || formState.length == 0 || formZip.length == 0){
					formData.set('q8_shescales-apply-company-address[country]','');
				}

				//Outputs all values in formData
				// const values = [...formData.entries()];
				// console.log(values);

				$.ajax({
					url: 'https://submit.jotform.com/submit/241936833574163/',
					type: 'post',
					data: formData,
					processData: false,
					contentType: false,
					success: function(response){
						ajaxLoader.hide();
						// console.log(response);
						$(".ss-apply__form-enrollment-open").fadeOut(function(){
							$("html, body").animate({scrollTop: "0"}, 1000);
							if (windowWidth >= 992) {
								$(".ss-apply__form-col-right").fadeIn();
							}
							$(".ss-apply__form-success-thankyou").fadeIn();
						});
					},
					error: function (response){
						console.log(response);
						ajaxLoader.hide();
					}
				});

			}
		}
	});

	//Apply Form validation
	$("#ss-apply-form").validate({
		errorClass: 'ss-error-message',
		errorPlacement: function (label, element) {
			element.parent().find("label:first").addClass("ss-error-label");
			element.parent().find("input").addClass("ss-error-input");
			label.insertAfter(element);
		},
		success: function(label, element) {
			$(element).parent().find("label:first").removeClass("ss-error-label");
			$(element).parent().find("input").removeClass("ss-error-input");
			label.remove();
		},
		messages: {
			"q3_shescales-apply-name[first]": "First name is required",
			"q3_shescales-apply-name[last]": "Last name is required",
			"q4_shescales-apply-email": "Please enter a valid email address",
			"q6_shescales-apply-company-name": "Company name is required",
			"q7_shescales-apply-company-title": "Your title is required",
			"q9_shescales-apply-company-website": "Company website is required"
		}
	});

	//Upload File
	$("#js-apply-attach-file").click(function(e){
		e.preventDefault();
		$(e.target).closest(".ss-apply__form-field").find("input").click();
	});

	//Upload File Change Event
	$('#apply_input_upload').change(function(e){

		$.each(e.target.files, function (index, value) {
			var fileSizeMB = value.size / (1024 * 1024);
			fileSizeMB = fileSizeMB.toFixed(2);

			// Upload Validation
			var checkFileSize = value.size / 1024;
			var checkFileExt = value.name.toLowerCase().split('.').pop();
			var allowFileExt = ["pdf","doc","docx","xls","xlsx","csv","txt","rtf","zip","jpg","jpeg","png","gif"];
			if (checkFileSize <= 10240 && allowFileExt.includes(checkFileExt)){
				$(e.target).parent().append("<div class='ss-apply__form-field-attachedItem'><span class='icon'></span>" + value.name + " (" + fileSizeMB + "MB) <a href='#' class='ss-close ss-apply-file-delete'></a></div>");
				$(e.target).closest(".ss-apply__form-field").find(".form-sub-label").hide();
				$('#apply_input_upload-error').removeClass('show');
			} else {
				$('#apply_input_upload').val('');
				$('#apply_input_upload-error').addClass('show');
			}
		});

	});

	//Remove Files
	$(document).on("click", ".ss-apply-file-delete", function(e) {
		e.preventDefault();
		$(e.target).closest(".ss-apply__form-field").find("input[type=file]").val("");
		$(e.target).closest(".ss-apply__form-field").find(".form-sub-label").show();
		$(e.target).parent().remove();
	});

}

// ===================================================================
// Section: Insights & Resources
// ===================================================================
function initInsightsResources(){
	//Insights & Resources
	var selectedIrFilter = ".element-item";

	//Show only 6 articles
	function resetArticleRows(initialLoad) {
		$(".element-item").addClass("element-item-hidden");

		$(selectedIrFilter).each(function(index, value) {
			if (index <= 5) {
					$(value).removeClass("element-item-hidden");
			} else {
					$(value).addClass("element-item-hidden");
			}
		});

		if (!initialLoad) {
			articlesGrid.isotope('layout');
		}
	}

	//Loads 3 more articles
	function loadMoreArticles() {
		$(selectedIrFilter + ".element-item-hidden").each(function(index, value) {
			if (index < 3) {
					$(value).show().removeClass("element-item-hidden");
					articlesGrid.isotope('layout');
			}
		});
	}

	//Decides whether or not to show the load more button
	function loadMoreVisibility() {
		if ($(selectedIrFilter + ".element-item-hidden").length <= 0) {
			$("#js-insights-load-more").hide();
		} else {
			$("#js-insights-load-more").show();
		}
	}

	resetArticleRows(true);

	//Initialize Isotope filtering
	var articlesGrid = $('#js-insights-list').isotope({
		itemSelector: '.element-item',
		layoutMode: 'vertical'
	});

	//Load More Resources
	$("#js-insights-load-more").click(function (e) {
		e.preventDefault();

		// Scroll up to the bottom of last article
		if (windowWidth > 991) {
			$('html, body').animate({scrollTop: $(this).offset().top - headerHeight}, 800);
		} else {
			$('html, body').animate({scrollTop: $(this).offset().top - headerHeight - irStickyNavHeight}, 800);
		}

		loadMoreArticles();
		loadMoreVisibility();
		enableIRstickyNav();
	});
	/*
	$("#js-resources-load-more").click(function (e) {
		e.preventDefault();
		ajaxLoader.css("display", "flex");
		setTimeout(function () {
			ajaxLoader.hide();
		}, 2000);
	});
	*/

	//Menu Functionality
	$(".ss-ir-content-filter, #insights-authors-dropdown, #mobile-articles-dropdown, #js-insights-mobile-menu-close").click(function(e){
		e.preventDefault();
		if ($(this).attr("id") === "insights-authors-dropdown"){
			toggleInsightsMenu(true, false);
		} else if ($(this).attr("id") === "mobile-articles-dropdown") {
			toggleInsightsMenu(true, true);
		} else if ($(this).attr("id") === "js-insights-mobile-menu-close") {
			hideAllInsightsMenus();
		} else {
			filterContent($(this));
		}
	});

	function filterContent(thisContent) {
		var filterValue = $(thisContent).attr('data-filter');
		selectedIrFilter = filterValue === "*" ? ".element-item" : filterValue;
		resetArticleRows(false);
		loadMoreVisibility();
		articlesGrid.isotope({filter: filterValue});
		hideAllInsightsMenus();
	}

	function hideAllInsightsMenus() {
		$("#js-insights-desktop-menu").fadeOut();
		$("#insights-authors-dropdown").removeClass("articles-dropdown-active");
		$("#mobile-articles-dropdown").removeClass("articles-dropdown-active");
		$("#js-insights-mobile-menu").fadeOut();
		$('#js-insights-mobile-menu').removeClass('show-author');
		$("#js-insights-mobile-submenu").slideUp();
		$("html, body").removeClass("no-scroll");
	}

	function toggleInsightsMenu(toggleDesktopMenu, toggleArrow) {
		if (windowWidth > 991 && toggleDesktopMenu) {
			$("#js-insights-desktop-menu").fadeToggle();
			$("#insights-authors-dropdown").toggleClass("articles-dropdown-active"); //always toggle arrow on desktop
		} else if (windowWidth <= 991) {
			if (toggleArrow) { //decides whether to allow modal to close and arrow to return to closed position - we dont do this on author click
				$("html, body").toggleClass("no-scroll");
				$("#mobile-articles-dropdown").toggleClass("articles-dropdown-active");
				$("#js-insights-mobile-menu").fadeToggle();
			} else {
				$("#js-insights-mobile-submenu").slideToggle();
				if ($('#js-insights-mobile-menu').hasClass('show-author')){
					$('#js-insights-mobile-menu').removeClass('show-author');
				} else {
					$('#js-insights-mobile-menu').addClass('show-author');
					setTimeout(function(){//yeah, I know this is hacky
						$('#js-insights-mobile-menu ul#js-insights-mobile-mainmenu').animate({scrollTop:1000}, 500);
					}, 500);
				}
			}
		}
	}

	//IR sticky nav for mobile
	enableIRstickyNav();

	function enableIRstickyNav() {
		if ($(".ss-ir-content")[0]) {
			if (irStickyNav) {
					irStickyNav.destroy(true);
					irStickyNav = null;
			}

			var resourcesNav = $(".ss-ir-content__col-left-menu-container-outer-sticky");
			var irStickyNavLoadMore = $("#js-insights-load-more").is(":visible") ? $("#js-insights-load-more").outerHeight() : 0;
			var irStickyNavDuration = $(".ss-ir-content__col-left").outerHeight() - $(".ss-ir-content__col-left-menu-container-outer").outerHeight() - irStickyNavLoadMore;

			if (windowWidth < 992) {
					irStickyNav = new ScrollMagic.Scene({
						triggerElement: ".ss-ir-detail",
						triggerHook: 0,
						duration: irStickyNavDuration,
						offset: $(".ss-ir-content").position().top - headerHeight
					}).addTo(controller);

					irStickyNav
						.on("enter", function (event) {
							resourcesNav.addClass("ss-ir-content-fixed");
							resourcesNav.removeClass("ss-ir-content-relative");
							resourcesNav.css("top", headerHeight).css("width", resourcesNav.parent().width());
						})
						.on("leave", function (event) {
							if (event.scrollDirection === "FORWARD") {
									resourcesNav.css("top", irStickyNavDuration).addClass("ss-ir-content-relative").removeClass("ss-ir-content-fixed");
							} else {
									resourcesNav.removeClass("ss-ir-content-fixed").removeClass("ss-ir-content-relative, ss-ir-content-fixed").css("top", "unset").css("width", "100%");
							}
						})
			} else {
					resourcesNav.removeClass("ss-ir-content-relative, ss-ir-content-fixed").css("top", "unset").css("width", "100%");
			}
		}
	}

	//IR Related Slider
	$(".ss-ir-related__slider").slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		spaceBetween: 30,
		responsive: [
			{
					breakpoint: 992,
					settings: {
						slidesToShow: 2
					}
			},
			{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerMode: true
					}
			}
		]
	});

	//IR sticky nav
	var irSocial = $(".ss-ir-social");
	if (irSocial[0]) {
		articleSocialShare();
	}

	function articleSocialShare() {
		if (socialShare) {
			socialShare.destroy(true);
			socialShare = null;
		}

		if (windowWidth >= 991) {
			irSocial.removeClass("ss-ir-social-mobile");

			socialShare = new ScrollMagic.Scene({
					triggerElement: ".ss-ir-detail",
					triggerHook: .5,
					duration: $(".ss-ir-detail").outerHeight() - $(".ss-ir-related").outerHeight() + $(".ss-newsletter").outerHeight(),
					offset: window.outerHeight / 2
			}).addTo(controller);

			socialShare
					.on("enter", function (event) {
						irSocial.fadeIn();
					})
					.on("leave", function (event) {
						irSocial.fadeOut();
					})
		} else {
			irSocial.fadeIn();
			irSocial.addClass("ss-ir-social-mobile");
		}
	}

}

// ===================================================================
// Section: Our Team
// ===================================================================
function initOurTeam(){
	//Our team and board detail pages - closes new opened tab on click of X
	$(".ss-our-team .ss-close, .ss-board-of-advisors .ss-close").click(function (e) {
		e.preventDefault();
		window.close();
	});
}

// ===================================================================
// Global Section: Header Menu
// ===================================================================
function initHeaderMenu(){
	if (isPage('home')){
		header.addClass("ss-header-animate");
		$("#navHome").addClass("nav-active");
	} else if (isPage('apply')){
		$("#navApply").addClass("nav-active");
	} else if (isPage('archive-insight') || isPage('single-insight')){
		$("#navIR").addClass("nav-active");
	}

	$('#js-header-menu').click(function(e) {
		e.preventDefault();
		$(this).toggleClass('ss-header__container-menu-icon-active');
		$('.ss-header__container-menu-items').toggleClass('ss-header__container-menu-items-active');
		$("html, body").toggleClass("no-scroll");
	});
}

// ===================================================================
// Global Section: Footer Contact Us Form
// ===================================================================
function initFooter(){
	//Footer Contact Us
	$("#js-footer-contact").click(function(e){
		e.preventDefault();
		if (windowWidth > 991) {
			$(".ss-footer").toggleClass("ss-footer-active");
		}
		$(e.target).toggleClass("js-footer-contact-active");
		$("#js-footer-contact-us").slideToggle();
		$('html,body').animate({
				scrollTop: $(".ss-footer").position().top - headerHeight
		}, 1000);
	});

	$("#js-footer-contact-us").validate({
		errorClass: 'ss-error-message',
		errorPlacement: function (label, element) {
			element.parent().find("input").addClass("ss-error-input");
			label.insertAfter(element).addClass("ss-error-label");
		},
		success: function(label, element) {
			$(element).parent().find("input").removeClass("ss-error-input");
			label.remove();
		},
		messages: {
			"q3_shescales-contact-name[first]": "First name is required",
			"q3_shescales-contact-name[last]": "Last name is required",
			"q4_shescales-contact-email": "Please enter a valid email address",
			"q7_shescales-contact-message": "Please provide a message"
		},
		submitHandler: function(form) {
			var postData = $(form).serializeArray();
			postData.push({name:'hostname', value:document.location.hostname});

			// Google reCAPTCHA
			grecaptcha.ready(function(){
				grecaptcha.execute('6Lf03z8eAAAAAIFmKhv7D9cCtt_uIx_FlNO-Ij37', {action: 'jotformSubmit'}).then(function(token){
					postData.push({name:'token', value:token});
					postData.push({name:'action', value:'jotformSubmit'});
					//console.log(postData);

					// JotForm: AJAX Submit
					$.ajax({
						type: "POST",
						url: WPTHEME+"jotform-contact-us.php",
						data: postData,
						cache: false
					}).done(function(data){
						//console.log("ajax returned: "+data);
						$('#js-footer-contact-us input[type=text]').val('');
						$('#js-footer-contact-us input[type=email]').val('');
						$('#js-footer-contact-us textarea').val('');
						$('#js-footer-contact-us').slideUp();
						$('footer.ss-footer').removeClass('ss-footer-active');
						setTimeout(function(){
							alert("Thank you!  Your message has been sent.");
						}, 1000);
					}).fail(function(xhr, textStatus, errorThrown){
						console.log("ajax failure: "+textStatus+"|"+errorThrown);
					});

				});
			});

		}
	});

}

// ===================================================================
// Global Section: Newsletter SignUp Module
// ===================================================================
function initNewsletterSignup(){
	$("#js-ss-newsletter-form").validate({
		errorClass: 'ss-error-message',
		errorPlacement: function (label, element) {
			element.parent().find("label:first").addClass("ss-error-label");
			element.parent().find("input").addClass("ss-error-input");
			label.insertAfter(element);
		},
		success: function(label, element) {
			$(element).parent().find("label:first").removeClass("ss-error-label");
			$(element).parent().find("input").removeClass("ss-error-input");
		},
		messages: {
			"FNAME": "First name is required",
			"LNAME": "Last name is required",
			"EMAIL": "Please enter a valid email address"
		},
		submitHandler: function(form) {
			form.submit();
			$("#js-ss-newsletter-form").find('#mce-FNAME').val('');
			$("#js-ss-newsletter-form").find('#mce-LNAME').val('');
			$("#js-ss-newsletter-form").find('#mce-EMAIL').val('');
		}
	});
}

// ===================================================================
// Global Function: ScrollMagic Fade Scenes
// ===================================================================
function initFadeScenes(){
	[].slice.apply( document.querySelectorAll(".fade-up, .fade-left, .fade-right, .fade-left-siblings, .fade-up-siblings, .fade-right-siblings") ).forEach(function(el){
		var opts = { triggerElement: el, triggerHook: 1, offset: 75 };
		var scene = new ScrollMagic.Scene(opts).addTo(controller);
		scene.on("start end", function(event) {
			$(el).addClass("in-view").trigger("inView");
			//console.log($(el));
		});
	});
}

// ===================================================================
// Global Function: Scroll To
// ===================================================================
function scrollTo(trigger, block){
	var scrollToHeader = headerHeight + homeBannerHeight; /* Home Banner */
	$(trigger).on('click', function(e){
		e.preventDefault();
		$('html,body').animate({
				scrollTop: $(block).position().top - scrollToHeader
		}, 1000);
	});
}

// ===================================================================
// Global Function: determines the page from WordPress body class/ID
// ===================================================================
function isPage(a){
	if ($('body#'+a).length){return true;}
	else if ($('body').hasClass(a)){return true;}
	else if ($('body').hasClass('page-'+a)){return true;}
	else if ($('body').hasClass('post-type-archive-'+a)){return true;}
	else if ($('body').hasClass('single-'+a)){return true;}
	else {return false;}
}